body {
  min-height: 0rem;
  padding-top: 4rem;
}

.z {
  width: 150%;
  position: absolute;
  right: 0;
  bottom: -40px;
}

.display-5 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.2;
}

@media (min-width: 768px) {
  .z {
    width: 100%;
    bottom: 0;
  }
}

html {
  font-size: .8rem;
}