.bd-sidebar {
  -ms-flex-order: 0;
  order: 0;
  border-bottom: 1px solid rgba(0,0,0,.1)
}

@media (min-width: 0px) {
  .bd-sidebar {
      border-right:1px solid rgba(0,0,0,.1)
  }

  @supports ((position: -webkit-sticky) or (position:sticky)) {
      .bd-sidebar {
          position:-webkit-sticky;
          position: sticky;
          top: 5rem;
          z-index: 1000;
          height: calc(100vh - 5rem)
      }
  }
}

@media (min-width: 0px) {
  .bd-sidebar {
      -ms-flex:0 0 320px;
      flex: 0 0 320px
  }
}

.bd-links {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: -15px;
  margin-left: -15px  
}

@media (min-width: 0px) {
  @supports ((position:-webkit-sticky) or (position:sticky)) {
      .bd-links {
          max-height:calc(100vh - 5rem);
          overflow-y: auto;
          overflow-x: hidden;
      }
  }
}

@media (min-width: 768px) {
  .bd-links {
      display:block!important
  }
}

.bd-content {
  -ms-flex-order: 1;
  order: 1
}

.bd-content>h2[id],.bd-content>h3[id],.bd-content>h4[id] {
  pointer-events: none
}

.bd-content>h2[id]::before,.bd-content>h3[id]::before,.bd-content>h4[id]::before {
  display: block;
  height: 6rem;
  margin-top: -6rem;
  content: ""
}

.bd-content>table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem
}

@media (max-width: 991.98px) {
  .bd-content>table {
      display:block;
      overflow-x: auto
  }

  .bd-content>table.table-bordered {
      border: 0
  }
}

.bd-content>table>tbody>tr>td,.bd-content>table>tbody>tr>th,.bd-content>table>tfoot>tr>td,.bd-content>table>tfoot>tr>th,.bd-content>table>thead>tr>td,.bd-content>table>thead>tr>th {
  padding: .75rem;
  vertical-align: top;
  border: 1px solid #dee2e6
}

.bd-content>table>tbody>tr>td>p:last-child,.bd-content>table>tbody>tr>th>p:last-child,.bd-content>table>tfoot>tr>td>p:last-child,.bd-content>table>tfoot>tr>th>p:last-child,.bd-content>table>thead>tr>td>p:last-child,.bd-content>table>thead>tr>th>p:last-child {
  margin-bottom: 0
}

.bd-content>table td:first-child>code {
  white-space: nowrap
}

.bd-content-title {
  display: block;
  pointer-events: auto
}

.bd-content>h2 {
  font-size: 2rem
}

@media (max-width: 1200px) {
  .bd-content>h2 {
      font-size:calc(1.325rem + .9vw)
  }
}

.bd-content>h3 {
  font-size: 1.75rem
}

@media (max-width: 1200px) {
  .bd-content>h3 {
      font-size:calc(1.3rem + .6vw)
  }
}

.bd-content>h4 {
  font-size: 1.5rem
}

@media (max-width: 1200px) {
  .bd-content>h4 {
      font-size:calc(1.275rem + .3vw)
  }
}

.bd-content>h2:not(:first-child) {
  margin-top: 3rem
}

.bd-content>h3 {
  margin-top: 1.5rem
}

.bd-content>ol li,.bd-content>ul li {
  margin-bottom: .25rem
}

@media (min-width: 992px) {
  .bd-content>ol,.bd-content>p,.bd-content>ul {
      max-width:80%
  }
}